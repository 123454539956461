import React from 'react';
import './Reviews.css'; // Make sure to create this CSS file for styling

const ReviewsData = [
  {
    quote: "Since using Habit Coach, I've developed a morning routine that sticks. It's transformed my days!",
    source: "Habit Formation",
    imgSrc: "/path/to/image1.png", // Replace with the actual path
  },
  {
    quote: "The AI-driven insights have helped me understand my patterns better and make meaningful changes.",
    source: "Insightful Tracking",
    imgSrc: "/path/to/image2.png", // Replace with the actual path
  },
  {
    quote: "Personalized coaching has been a game-changer. It feels like having a mentor guiding you through.",
    source: "Personalized Coaching",
    imgSrc: "/path/to/image3.png", // Replace with the actual path
  },
];

function Reviews() {
  return (
    <div className="hero_footer_quotes">
      {ReviewsData.map((review, index) => (
        <div className={`hero_footer_quote quote_${index + 1}`} key={index}>
          <p>"{review.quote}"</p>
          <img alt={review.source} src={review.imgSrc} />
        </div>
      ))}
    </div>
  );
}

export default Reviews;
