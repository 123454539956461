import * as React from 'react';
import './Pricing.css';

function PricingPage(userEmail) {

  return (
    <div className="pricing-form">
{/* <stripe-pricing-table
pricing-table-id="prctbl_1OrxgcKtYAolXsk1N55DjYDv"
publishable-key="pk_live_51NP0ScKtYAolXsk1hPa6UGGMGwvFkQBbkWp42fdAa05ebqJoCqtgfQB0bl9j6WabrrmHw08bPKXzTYWSVkmUFzGM00lndPZAkS"
customer-email={userEmail.userEmail}
>
</stripe-pricing-table> */}


<stripe-buy-button
  buy-button-id="buy_btn_1P2g2PKtYAolXsk1dkTamejl"
  publishable-key="pk_test_51NP0ScKtYAolXsk1g3CfN1C8eeSN7AMn1nagGJ89BgVTm5Qvk7tEEa3wHZ0MbklHeSQ7KEr27IxDuUkqDwfcjd9J00dtJNMja6"
>
</stripe-buy-button>
</div>
  );
}

export default PricingPage;