// HabitCreateandTrack.js
import React , {useEffect} from 'react';
import Banner from './components/Banner';
import Header from './components/Header';
import HabitProgress from './components/HabitProgress';
import './App.css';
import LoginRegistration from './LoginRegistration';
import {  Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function HabitCreateandTrack({
    user, handleSignIn, handleSignOut, handleCreateHabit, responseMessage,
    handleSave, userId, fetchHabitDetails, fetchHabitTitles, habitTitles_default,
    fetchProgressUpdates, addProgressUpdate, generateResponseProgress, handleMicClick,deleteHabit, userIsLoading,addstatusUpdate
  }) 
  
  { 
    
    let navigate = useNavigate();

    useEffect(() => {
      // Redirects to '/app' if there is no user authenticated
      if (!user && !userIsLoading) {
        navigate('/LoginRegistration');
      }
    }, [user, userIsLoading, navigate]); // Depend on user and userIsLoading to run effect when they change
  
    if (userIsLoading) return <div>Loading...</div>;

  return (
    <div>


<>
      <Banner />
      <Header
        user={user}
        handleSignIn={handleSignIn}
        handleSignOut={handleSignOut}
        handleCreateHabit={handleCreateHabit}
        responseMessage={responseMessage}
        handleSave={handleSave}
        userId={userId}
        fetchHabitTitles={() => fetchHabitTitles(userId)}
        deleteHabit ={deleteHabit}
      />
      <HabitProgress
        userId={userId}
        fetchHabitDetails={() => fetchHabitDetails(userId)}
        habitTitles_default={habitTitles_default}
        fetchProgressUpdates={fetchProgressUpdates}
        fetchHabitTitles={fetchHabitTitles}
        addProgressUpdate={addProgressUpdate}
        addstatusUpdate ={addstatusUpdate}
        generateResponseProgress={generateResponseProgress}
        handleMicClick={handleMicClick}
       
      />
      </>

    </div>
  );
}

export default HabitCreateandTrack;
