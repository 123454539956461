const EventBus = {
    events: {},
    dispatch: function(event, data) {
      if (!this.events[event]) return; // No listeners for this event
      this.events[event].forEach(callback => callback(data));
    },
    subscribe: function(event, callback) {
      if (!this.events[event]) this.events[event] = []; // Create a new event array if it doesn't exist
      this.events[event].push(callback);
    },
    unsubscribe: function(event, callback) {
      if (!this.events[event]) return; // No such event
      this.events[event] = this.events[event].filter(cb => cb !== callback);
    }
  };
  
  export default EventBus;
  