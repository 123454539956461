import React, { useState, useRef, useEffect, useCallback } from 'react';
import { functions, httpsCallable, getAuth, signOut, signInWithPopup, signInWithRedirect, provider, db, analytics} from './firebase';
import { deleteDoc ,collection, addDoc, Timestamp, query, where, orderBy, getDocs, limit,doc, runTransaction, updateDoc  } from 'firebase/firestore';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import './App.css';
import './components/MicIcon.css';
import { useSubmissionStatus } from './components/SubmissionStatusContext';
import LandingPage from './LandingPage.js';
import HabitCreateandTrack from './HabitCreateandTrack.js';
import LoginRegistration from './LoginRegistration.js';
import EventBus from './EventBus.js';



function App() {
  const [recording, setRecording] = useState(false);
  const detailPageRef = useRef(null);
  const [setting, setSetting] = useState('personal');
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);    
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [userId, setUserId] = useState(null);   
  const [popupMessage, setPopupMessage] = useState("");
  const [isSaved, setIsSaved] = useState(false); 
  const [showModal, setShowModal] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [feedbackResult, setFeedbackResult] = useState(null);
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [habitDetails, setHabitDetails] = useState(null);
  const [habitTitles, setHabitTitles] = useState(null);
  const [createHabitResponse, setCreateHabitResponse] = useState({});
  const { setIsSubmitting, setIsSubmissionComplete } = useSubmissionStatus();
  const [successMessage, setSuccessMessage] = useState('');
  const [userIsLoading, setuserIsLoading] = useState(true);
  const [redirectToApp, setRedirectToApp] = useState(false);



  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authUser => {
      console.log('authUser:', authUser);  // Corrected to log authUser
      setUser(authUser);
      if (authUser) {
        setUserId(authUser.uid);
        setRedirectToApp(!!authUser); // Set to true if authUser is not null
        console.log('userId set to:', userId);
      } else {
        setUserId(null); // Explicitly set to null if no user
        console.log('userId set to null');
      }
      setuserIsLoading(false); // Authentication check is complete
    });
    
    return () => unsubscribe();    
  }, [auth]);  // useEffect dependency remains the same
  


useEffect(() => {
  console.log('userId updated to:', userId);  
}, [userId]); // This useEffect depends on userId and runs whenever userId changes.



  const handleSignIn = () => {
    if (isMobileDevice()) {
      signInWithRedirect(auth, provider);
    } else {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        saveUserData(user);
        setUserId(user.uid);  // Set the userId state
      })
      .catch((error) => {
        console.error(error);
      });
    }
  };

  const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const saveUserData = (user) => {
    // ... your logic to save user data to Firebase
  };
 
  const handleMicClick = (isStartRecording, updateTranscription, onTranscriptionComplete) => {
    if (isStartRecording) {      
      handleStartRecording();
    } else {

    const handleStopRecording = async () => {
      if (!mediaRecorder.current) {
          console.error('MediaRecorder is not initialized.');
          EventBus.dispatch('audioerror', true);
          setIsSubmitting(false); // End submitting
          return;
      }  
  // Prepare to stop the media recorder
  await new Promise(resolve => {
    mediaRecorder.current.onstop = () => {
      resolve();
      // Stop each track of the media stream
      if (mediaRecorder.current && mediaRecorder.current.stream && mediaRecorder.current.stream.getTracks) {
        mediaRecorder.current.stream.getTracks().forEach(track => track.stop());        
   
      }
      mediaRecorder.current = null;
    };
    mediaRecorder.current.stop();
  });
setRecording(false);  

      const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });  
      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      reader.onloadend = async () => {
      const base64data = reader.result.split(',')[1];  
          try {
              setWaitingForResponse(true); // <-- Add this line before making the function calls
             // setPopupMessage("Transcribing..."); // Show transcribing indicator
             EventBus.dispatch('transcriptionStarted', true);
              const transcribeAudioFunction = httpsCallable(functions, 'transcribeAudio');
              const result = await transcribeAudioFunction({ audioBase64: base64data });
            //  console.log('Result from transcribeAudioFunction:', result); // Add this line  
              //setPopupMessage(result); // Show transcribing indicator
              if (result.data && result.data.transcription) {
                  const transcribedText = result.data.transcription;
                  setTranscription(transcribedText);
                  //setPopupMessage(transcribedText); // Show transcribing indicator
                  console.log('Result from transcribedText:', transcribedText); // Add this line
                  updateTranscription(transcribedText); // Update the progress note                 
                    onTranscriptionComplete(transcribedText); // Call the callback after transcription
                  } else {
                  console.error("Error transcribing audio: transcription not found in result");
                  EventBus.dispatch('audioerror', true);
                  setIsSubmitting(false); // End submitting
              }
          } catch (error) {
              console.error("Error calling cloud function:", error);
              setIsSubmitting(false); // End submitting

          }finally {
            setWaitingForResponse(false);
            EventBus.dispatch('transcriptionCompleted', true);
            console.log(waitingForResponse)// <-- Set it back to false after receiving the response
          };
        };
      };
      handleStopRecording();
    }
    console.log("Tested Data", transcription);
  };

  const handleUserChange = (newUserId) => {
      setUserId(newUserId);
  }



  // Function to handle saving feedback text
  const handleSave = async (habitData) => {
   // setIsSubmitting(true); // Start submitting
    setIsSubmissionComplete(false); // Reset submission complete state
    console.log("HabitData", habitData);

   // const { title, feedbackText, startDate, frequency, isActive } = habitData;
   // console.log("This is an example test", habitData);

    try {
      await saveToFirestore(userId, habitData);
      setIsSaved(true);
      console.log('Data saved successfully');
      setIsSubmitting(false); // End submitting
      setIsSubmissionComplete(true); // Mark submission as complete
      setTimeout(() => setIsSubmissionComplete(false), 3000); // Reset after a delay
      setCreateHabitResponse('');
    } catch (error) {
      console.error("Error saving to Firestore:", error);
      setIsSaved(false);
      setIsSubmitting(false); // End submitting in case of error
    }
  };

// Function to delete a habit
const deleteHabit = async (habitId) => {
  if (!userId) {
    alert('User not logged in');
    return;
  }
  
  const confirmed = window.confirm('Are you sure you want to delete this habit?');
  const subcollections = ['Progress', 'AnotherSubcollection']; 
  if (confirmed) {
    const habitDocRef = doc(db, 'Habits', userId, 'UserHabits', habitId);
    try {
      for (const subcollection of subcollections) {
        const collectionRef = collection(habitDocRef, subcollection);
        const snapshot = await getDocs(collectionRef);
        snapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
      }
 
      await deleteDoc(habitDocRef);
      console.log(`Habit ${habitId} deleted successfully.`);
      window.confirm('Habit deleted successfully');
      EventBus.dispatch('habitDeleted', true);

    } catch (error) {
      console.error('Error deleting habit:', error);
    }
  }
};

  const handleStartRecording = async () => {
      try {
          // Clear the audioChunks array for the new recording
          audioChunks.current = [];
              // Clear the transcription state to hide the transcription icon
              setTranscription('');        
              setFeedbackResult(''); 
          
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          mediaRecorder.current = new MediaRecorder(stream);
          mediaRecorder.current.ondataavailable = (event) => {
              audioChunks.current.push(event.data);
          };
          mediaRecorder.current.start();
          console.log('Recording started'); // Add log statement
          console.log(mediaRecorder.current.state);
  
          setRecording(true);
          // Inside handleStartRecording
EventBus.dispatch('recordingStarted', true);
  
      } catch (error) {
          console.error("Error starting recording:", error);
          setIsSubmitting(false); // End submitting
      }
  };
      
  const convertToJSON = (response) => {
    const cleanJsonString = (jsonString) => {
      return jsonString
        .replace(/,\s*([}\]])/g, '$1') // Remove trailing commas before closing braces or brackets
        .replace(/\n/g, "\\n") // Escape new lines
        .replace(/\r/g, "\\r") // Escape carriage returns
        .replace(/\t/g, "\\t") // Escape tabs
        .replace(/\\'/g, "\\'") // Escape single quotes
        .replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2":') // Ensure keys are double-quoted
        .trim(); // Trim whitespace
    };
  
    // Function to try parsing a JSON string
    const tryParseJSON = (jsonString) => {
      try {
        const cleanedString = cleanJsonString(jsonString);
        return JSON.parse(cleanedString); // Attempt to parse cleaned string
      } catch (error) {
        
        return {
          error: "Failed to parse JSON",
          errorMessage: error.toString(),
          originalString: jsonString
        };
      }
    };
  
    // Check if the response is a string and attempt to parse it
    if (typeof response === 'string') {
      return tryParseJSON(response);
    } 
    // Check if the response is an object with a 'feedback' property that needs parsing
    else if (typeof response === 'object' && response !== null && typeof response.feedback === 'string') {
      try {
        response.feedback = JSON.parse(response.feedback);
      } catch (error) {
        // Handle errors in parsing 'feedback' by setting it to a standardized error object
        response.feedback = {
          error: "Failed to parse 'feedback' as JSON",
          errorMessage: error.toString(),
          originalString: response.feedback
        };
      }
      return response;
    }
    // Handle generic objects by recursively parsing their string properties
    else if (typeof response === 'object' && response !== null) {
      Object.keys(response).forEach(key => {
        response[key] = convertToJSON(response[key]);
      });
      return response;
    } else {
      // Return an error if the input type is not supported for JSON conversion
      console.error("Unsupported input type for JSON conversion:", typeof response);
      return {
        error: "Unsupported input type for JSON conversion",
        originalData: response
      };
    }
  };
  
   
  async function generateResponseProgress(habitFeedback, progressNote) {
    const transcribedText = `My Today's progress is ${progressNote}. My goal is: ${habitFeedback}.`;
    try {
        const generateResponseProgressFunction = httpsCallable(functions,'generateResponseProgress');
        const result = await generateResponseProgressFunction({ transcribedText, setting });
        console.log(result.data);
        let formattedFeedback; 
        setIsSubmissionComplete();
   try {       
        formattedFeedback = convertToJSON(result.data); // Call convertToJSON here
        console.log("Formatted Feedback", formattedFeedback);
        return formattedFeedback;
      } catch (parseError) {
        console.error("Error parsing JSON:", parseError);       
        formattedFeedback = convertToJSON({ feedback: result.data.feedback });
        console.log("Formatted Feedback", formattedFeedback);
        return formattedFeedback;
      }

    } catch (error) {
        console.error("Error calling cloud function:", error);
        return {
            feedback: {
                "Feedback": "Error generating feedback"
            }
        };
    }
    
 }

 // Function to call the createHabit Cloud Function
 

 const handleCreateHabit = async (habitText) => {
  setLoading(true); // Assuming setLoading is defined in app.js
  console.log('InputText:', habitText);
   try {
    const createHabit = httpsCallable(functions, 'createHabit');
    const result = await createHabit({ habitText: habitText });    
     console.log('Habit suggested:', result.data);
    // console.log('Habit suggested feedback:', result.data.feedback);

    // console.log('Habit created:', result.data.feedback);
     if (result.data && result.data.feedback) {
      const parsedFeedback = convertToJSON(result.data);
      setCreateHabitResponse(parsedFeedback);
     // setCreateHabitResponse(result.data);
      console.log('Habit createHabitResponse:', parsedFeedback);
  } else {
      console.error('No feedback received');
      setCreateHabitResponse({});
  }
   } catch (error) {
     console.error('Error creating habit:', error);
     setCreateHabitResponse('Error creating habit');
   } finally {
     setLoading(false);
   }
 };

 const saveToFirestore = async (userId, habitData) => {
    if (!userId) {
        console.error('User not logged in');
        return;
      }
      
    try {
      const userFeedbackRef = collection(db, 'Habits', userId, 'UserHabits');      
      console.log('feedbackResult', habitData);    
      await addDoc(userFeedbackRef, habitData);      
      console.log('Data saved successfully');
      EventBus.dispatch('habitCreatedOrUpdated', true);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const fetchHabitTitles = useCallback(async () => {
  //     // Check for cached titles first
  // const cachedTitles = sessionStorage.getItem('habitTitles');
  // if (cachedTitles) {
  //   setHabitTitles(JSON.parse(cachedTitles));
  //   return;
  // }

    if (!userId) {
      console.error('User not logged in');
      return [];
    }
  
    try {
      const userHabitsRef = collection(db, 'Habits', userId, 'UserHabits');
      const q = query(userHabitsRef, orderBy('lastStatusUpdated', 'desc'));
      const querySnapshot = await getDocs(q);
      const titles = querySnapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title, // Ensure 'title' field exists in the documents
        summary: doc.data().summary, // Include 'feedback' field
        lastUpdated: doc.data().lastUpdated // Only fetching 'lastUpdated', excluding other details
      }));
      setHabitTitles(titles); // Set the state with the fetched titles 
      // sessionStorage.setItem('habitTitles', JSON.stringify(titles)); // Cache the titles     
      return titles;
    } catch (error) {
      console.error("Error fetching habit titles:", error);
      return []; // Return an empty array in case of error
    }
  }, [userId]);
  

  const fetchHabitDetails = useCallback(async () => {
    if (!userId) {
      console.error('User not logged in');
      return [];
    }
  
    try {
      const userHabitsRef = collection(db, 'Habits', userId, 'UserHabits');
      const q = query(userHabitsRef);
      const querySnapshot = await getDocs(q);
      const details = querySnapshot.docs.map(doc => ({ 
        id: doc.id, 
        title: doc.data().title, // Make sure 'title' field exists in the documents
        summary: doc.data().summary, // Include 'feedback' field
        pitfalls: doc.data().pitfalls, // Include 'feedback' field
        mentalModel: doc.data().mentalModel, // Include 'feedback' field
        plan: doc.data().plan, // Include 'feedback' field
        lastUpdated: doc.data().lastUpdated, // Include 'feedback' field
        url: doc.data().url // Include 'feedback' field
      }));
      setHabitDetails(details); // Set the state with the fetched titles
      console.error(details);
      return details;
    } catch (error) {
      console.error("Error fetching habit details:", error);
      return []; // Return an empty array in case of error
    }
  }, [userId]);
  
  // Fetch habit titles when userId changes
  useEffect(() => {
    if (userId) {
      fetchHabitTitles();
    } else {
      setHabitTitles(null); // Reset when logged out
    }
  }, [userId]);

const fetchProgressUpdates = async (userId, habitId) => {
  if (!userId || !habitId) {
    console.error('Missing userId or habitId');
    return [];
  }

  try {
    const progressRef = collection(db, 'Habits', userId, 'UserHabits', habitId, 'Status');
    const q = query(progressRef, orderBy('date', 'desc'), limit(10));
    const querySnapshot = await getDocs(q);
    console.log("Fetched", querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })));

    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error("Error fetching progress updates:", error);
    return [];
  }
};

const addProgressUpdate = async (userId, habitId, progressNote, habitFeedback, status = 'In Progress', generateResponseProgress) => {
  if (!userId || !habitId) {
    console.error('Missing userId or habitId');
    return;
  }

  try {
    const progressRef = doc(collection(db, 'Habits', userId, 'UserHabits', habitId, 'Progress'));
    const progressData = {
      date: Timestamp.now(), // Current timestamp
      progressNote, // Note about the progress
      status, // Status of the progress (default to 'In Progress' if not provided)
    };
 // Reference to the specific habit document
 const habitDocRef = doc(db, 'Habits', userId, 'UserHabits', habitId);    
     const response = await generateResponseProgress(progressNote, habitFeedback);
     console.log('Response from generateResponseProgress:', response);
           // Start a transaction
    await runTransaction(db, async (transaction) => {   
      transaction.set(progressRef, progressData);   
      transaction.update(habitDocRef, { lastUpdated: Timestamp.now() });
      console.log('Progress updated successfully');
    });

     return response; // Return the response

  } catch (error) {
    console.error('Error updating progress:', error);
  }
};

const addstatusUpdate = async (userId, habitId,status) => {
  if (!userId || !habitId) {
    console.error('Missing userId or habitId');
    return;
  }

  try {
    const progressRef = doc(collection(db, 'Habits', userId, 'UserHabits', habitId, 'Status'));
    const progressData = {
      date: Timestamp.now(), // Current timestamp
      status, 
    };
 // Reference to the specific habit document
 const habitDocRef = doc(db, 'Habits', userId, 'UserHabits', habitId);    
    await runTransaction(db, async (transaction) => {   
      transaction.set(progressRef, progressData);   
      transaction.update(habitDocRef, { lastStatusUpdated: Timestamp.now() });
      console.log('Progress updated successfully');
    });

    return { success: true, message: 'Great Job!' };

  } catch (error) {
    console.error('Error updating progress:', error);
    return { success: false, message: `Error updating progress: ${error.message}` };
  }
};



  const findStaleUserHabitsAndNotify = async (userId) => {
    const user = auth.currentUser;
    if (!user) {
      console.log('No user is signed in.');
      return;
    }
  
    const twentyFourHoursAgo = Timestamp.fromDate(new Date(Date.now() - 24 * 60 * 60 * 1000));
    const userHabitsRef = collection(db, `Habits/${user.uid}/UserHabits`);
    const staleHabitsQuery = query(userHabitsRef, where('lastUpdated', '<', twentyFourHoursAgo));
  
    try {
      const querySnapshot = await getDocs(staleHabitsQuery);
      if (querySnapshot.empty) {
        console.log(`No stale habits found for user ${user.uid}.`);
        return;
      }
  
      // Aggregate all stale habits into a single HTML message
      let emailHtml = "You have the following stale habits that need your attention:<br/>";
      querySnapshot.forEach(doc => {
        const habitData = doc.data();
        const lastUpdated = habitData.lastUpdated.toDate();
        const title = habitData.title;
        emailHtml += `Title: "${title}", last updated on ${lastUpdated}.<br/>`;
      });
  
      // Save a single message to the 'mail' collection
      await addDoc(collection(db, "mail"), {
        to: [user.email], // Use the signed-in user's email
        message: {
          subject: "Stale Habit Notification",
          html: emailHtml,
        },
      });
  
      console.log(`Notification process initiated for user ${user.uid}.`);
    } catch (error) {
      console.error("Error querying for stale habits:", error);
    }
  };

  // useEffect(() => {
  //   if (redirectToApp) {
  //     // Perform the redirect
  //     window.location.href = '/app'; // This will cause a full page reload to '/app'
  //     // Alternatively, you could use history.push('/app') with react-router-dom v5 or navigate('/app') with v6 if you want to avoid a full page reload
  //   }
  // }, [redirectToApp]);

  return (

 
    <div className="container">

<Routes>



        <Route path="/" element={<LandingPage />} />
        <Route path="/LoginRegistration" element={<LoginRegistration />} />   
             
        <Route path="/app" element={ 
    <HabitCreateandTrack
                user={user} 
                handleSignIn={handleSignIn} 
                handleSignOut={handleSignOut} 
                handleCreateHabit={handleCreateHabit}
                responseMessage={createHabitResponse} 
                handleSave={handleSave} 
                userId={userId}
                fetchHabitDetails={fetchHabitDetails} // Directly pass the function reference
                fetchHabitTitles={fetchHabitTitles} // Directly pass the function reference
                habitTitles_default={habitTitles}
                fetchProgressUpdates={fetchProgressUpdates}
                addProgressUpdate={addProgressUpdate}
                addstatusUpdate ={addstatusUpdate}
                generateResponseProgress={generateResponseProgress}      
                handleMicClick={handleMicClick}
                deleteHabit={deleteHabit} // Pass the deleteHabit function as a prop    
                userIsLoading={userIsLoading}
                /> 
           } />
      </Routes>
            </div>
             
    
  );
}

export default App;
