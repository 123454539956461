import React, { useState, useEffect } from 'react';
import { useSubmissionStatus } from './SubmissionStatusContext'; // Import the context hook
import './ManageHabits.css';
import SwipeableViews from 'react-swipeable-views';
import { useLocation } from 'react-router-dom';



const ManageHabits = ({ userId, fetchHabitTitles,deleteHabit}) => {
  const [habitTitles, setHabitTitles] = useState([]);
  const [habitFeedback, setHabitFeedback] = useState([]);
  const [Plan, setPlan] = useState([]);
  const [Title, setTitle] = useState([]);
  const [Mentalmodel, setMentalmodel] = useState([]);
  const [Pitfalls, setPitfalls] = useState([]);
 


  const [selectedHabitId, setSelectedHabitId] = useState('');
  const [progressNote, setProgressNote] = useState('');
  const [progressUpdates, setProgressUpdates] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(false);

  const [responseMessage, setResponseMessage] = useState(null);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [showAnalyticsPopup, setshowAnalyticsPopup] = useState(false);


  const { isSubmitting, setIsSubmitting, isSubmissionComplete, setIsSubmissionComplete } = useSubmissionStatus();
  const [progressMessage, setprogressMessage] = useState("");
  const [transcribedText, setTranscribedText] = useState('');
  const [latestUpdateDate, setLatestUpdateDate] = useState('');
 

  const [index, setIndex] = useState(0); // New state for tracking SwipeableViews index

  const location = useLocation();
  const getQueryParamValue = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('docid'); // Note: Query parameter keys are case-sensitive
};

const [docId, setDocId] = useState('');

  const handleNext = () => {
      if (index < 3) { // Assuming there are 3 swipeable views
          setIndex(prevIndex => prevIndex + 1);
      }
  };

  useEffect(() => {
    const docIdValue = getQueryParamValue();
    if (docIdValue) {
        setDocId(docIdValue);
        setSelectedHabitId(docIdValue);
        console.log("Captured docId from query:", docIdValue);
        // You can now use docIdValue for fetching data or other logic
    }
}, [location.search]); // React to changes in the query string
  // Function to navigate to the previous card

  const handlePrev = () => {
      if (index > 0) {
          setIndex(prevIndex => prevIndex - 1);
      }
  };

  

  const toggleFeedbackPopup = () => {
    setShowFeedbackPopup(!showFeedbackPopup);
  };



  
    useEffect(() => {
      console.log("Checking-->");
      let isMounted = true; // Declare isMounted flag  
    
      if (userId) {
        fetchHabitTitles().then(titles => {    
          if (isMounted) { // Check isMounted before setting state 
              const sortedTitles = titles.sort((a, b) => {
              const aDate = a.lastUpdated && a.lastUpdated.toDate ? a.lastUpdated.toDate() : new Date(0);
              const bDate = b.lastUpdated && b.lastUpdated.toDate ? b.lastUpdated.toDate() : new Date(0);
              return bDate - aDate;
            });
    
          //  console.log("Sorted titles and lastUpdated dates:", sortedTitles.map(title => ({ title: title.id, lastUpdated: title.lastUpdated ? title.lastUpdated.toDate() : 'No date' }))); // Log sorted titles and lastUpdated dates
    
            setHabitTitles(sortedTitles);
    
            if (sortedTitles.length > 0) {if (!selectedHabitId && !docId) {
                setSelectedHabitId(sortedTitles[0].id);
                if (sortedTitles[0].lastUpdated && sortedTitles[0].lastUpdated.toDate) {
                  setLatestUpdateDate(sortedTitles[0].lastUpdated.toDate());
                } else {
                  setLatestUpdateDate(new Date());
                }
              }
            }
          }
        });
      }
      return () => {
        isMounted = false; // Set isMounted to false when the component unmounts
      };
     
    }, [userId, docId]);
        
 
  useEffect(() => {
   
    const selectedHabit = habitTitles.find(habit => habit.id === selectedHabitId);
    console.log("Checking", selectedHabit);
    if (selectedHabit) {
      setHabitFeedback(selectedHabit.summary);
      setPlan(selectedHabit.plan);
      setTitle(selectedHabit.title);
      setMentalmodel(selectedHabit.mentalModel);
      setPitfalls(selectedHabit.pitfalls);
      if (selectedHabit.lastUpdated?.toDate) {
        setLatestUpdateDate(selectedHabit.lastUpdated.toDate());
      }
    }
  }, [selectedHabitId, habitTitles]); // Add this useEffect to update feedback when selected habit changes

  useEffect(() => {
    console.log("Checking");
    let feedbackOutput = '';

    if (responseMessage) {
        if (typeof responseMessage === 'object' && responseMessage.feedback) {
            // Directly use the value of 'feedback' key if it exists
            feedbackOutput = responseMessage.feedback;
        } else if (typeof responseMessage === 'string') {
            // If the responseMessage is a string, use it directly
            feedbackOutput = responseMessage;
        }

        setResponseMessage(feedbackOutput);
    }
}, [responseMessage]);

const jsonToHtml = (json) => {
  let html = "";
  try {
    // Assuming `json` is either a JSON string or object
    const obj = typeof json === "string" ? JSON.parse(json) : json;

    // Generate HTML content
    html = Object.entries(obj).map(([key, value]) => {
      // For nested objects, recursively convert them to HTML
      if (typeof value === "object" && value !== null) {
        return `<div><strong>${key}:</strong><div style="padding-left: 20px;">${jsonToHtml(value)}</div></div>`;
      } else {
        // For simple key-value pairs, generate a simple line
        return `<div><strong>${key}:</strong> ${value}</div>`;
      }
    }).join("");
  } catch (error) {
    console.error("Error converting JSON to HTML:", error);
    html = "Failed to parse message content.";
  }
  return html;
};





  // New function to navigate to the next habit
  const navigateHabits = (direction) => {
    const currentIndex = habitTitles.findIndex(habit => habit.id === selectedHabitId);
    let newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

    // Loop back if navigating beyond the start or end of the array
    if (newIndex < 0) {
      newIndex = habitTitles.length - 1;
    } else if (newIndex >= habitTitles.length) {
      newIndex = 0;
    }

    setSelectedHabitId(habitTitles[newIndex].id);
  };

  function renderFeedback(feedback) {
    return Object.entries(feedback).map(([key, value]) => (
      <div key={key}>
        <strong>{key.replace(/_/g, ' ')}:</strong> {value}
      </div>
    ));
  }

  return (
    <div className="habit-edit-form">
            <div className="headline" >
        <b>Edit Habits</b>
        </div>      

      <div className="edit_dropdown">    
      <div className="edit_container">
              <select value={selectedHabitId} onChange={(e) => setSelectedHabitId(e.target.value)}>
          {habitTitles.map((habit, index) => (
            <option key={index} value={habit.id}>{habit.title}</option>
            
          ))}
          
          </select>
</div>

<div className="edit-buttons-container">

    <div>{habitFeedback} </div>
    
    <button 
  onClick={() => deleteHabit(selectedHabitId)}
  style={{ backgroundColor: 'red', color: 'white', border: 'none', cursor: 'pointer', padding: '10px 20px', borderRadius: '5px' }}
>
  Delete Habit
</button>



  </div>





      </div> 


            
      {showFeedbackPopup && (
             
        <div class="popup-background" onClick={toggleFeedbackPopup}>
          <div className="popup">
          <div class="popup-title">{Title}</div>
          <div className="popup-content" onClick={e => e.stopPropagation()}>     
            
            <div className="popup-content">
            <SwipeableViews index={index} onChangeIndex={setIndex} className="swipeable-sections">
            <div className="card">
            <div className="card-header">Summary</div>      {habitFeedback} 
            </div>
            <div className="card">
            <div className="card-header">Plan</div>
            {Plan}
            </div>
            <div className="card">
            <div className="card-header">Mental Model</div>
            {Mentalmodel}
            </div>
            <div className="card">
            <div className="card-header">Pitfalls to Avoid</div>
            {Pitfalls}
            </div>
              
              </SwipeableViews>
              
               </div>
        
          </div>          
          <div class="popup-footer"></div>
   </div>
        </div>
      )}

    </div>
  );
};

export default ManageHabits;
