import React, { useState } from 'react';
import './LoginRegistration.css';
import { db, auth, signInWithPopup, provider, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, updateProfile  } from './firebase';
import { doc, setDoc } from 'firebase/firestore';
import 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import logoImage from './components/images/logo_habitify.png';

const LoginRegistration = () => {
  const navigate = useNavigate();
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [error, setError] = useState(''); // State to store the error message

  // Call this function to display an error message for a few seconds
  const showError = (message) => {
    setError(message);
    setTimeout(() => setError(''), 5000); // Hide the error message after 5 seconds
  };

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider).then((result) => {
      const user = result.user;
      saveUserDataToFirestore(user); // Save the Google user data to Firestore
      navigate('/app');
    }).catch(error => {
      
      showError('Failed to sign in with Google. Please try again.');
    });
  };

  const handleEmailPasswordAuth = async () => {
    if (isRegistering) {
      try {
        const result = await createUserWithEmailAndPassword(auth, email, password);  
        const user = result.user; 
        await updateProfile(user, {
          displayName: name
        });
            // You may want to save the user data elsewhere in your database as well
            saveUserDataToFirestore(user);
        navigate('/app');
      } catch (error) {
        console.error("Error registering with email and password:", error.message);
        showError(error.message);
      }
    } else {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/app');
      } catch (error) {
        console.error("Error signing in with email and password:", error.message);
        showError(error.message);
      }
    }
  };

  const saveUserDataToFirestore = async (user) => {
    const userRef = doc(db, 'users', user.uid); // Reference to the user document in Firestore
    try {
      await setDoc(userRef, {
        email: user.email,
        displayName: name || user.displayName, // Use the name entered in the form or the one from Google
      });
      console.log("User data saved to Firestore");
    } catch (error) {
      console.error("Error saving user data to Firestore:", error);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      alert("Please enter your email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent!");
      setResetPasswordMode(false); // Reset back to login mode after sending email
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      showError('Failed to send password reset email. Please check the email address and try again.');
    }
  };

  const renderAuthForm = () => {
    return (
      <>
        {isRegistering && 
        <input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />}
        <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
        {!resetPasswordMode && <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />}
        {!resetPasswordMode ? (
          <button onClick={handleEmailPasswordAuth}>{isRegistering ? "Register" : "Sign In"}</button>
        ) : (
          <button onClick={handleForgotPassword}>Reset Password</button>
        )}
        {!isRegistering && !resetPasswordMode && (
          <>
            <a onClick={() => setIsRegistering(true)}>Register Instead</a>
            <a className="forgot-password" onClick={() => setResetPasswordMode(true)}>Forgot Password?</a>
          </>
        )}
        {isRegistering && !resetPasswordMode && <a onClick={() => setIsRegistering(false)}>Back to Sign In</a>}
        {resetPasswordMode && <a onClick={() => setResetPasswordMode(false)}>Back to Sign In</a>}
      </>
    );
  };

  return (
    <div className="login-container">
      <img src={logoImage} alt="Logo" className="centered-logo" />
      {error && <div className="auth-error">{error}</div>} {/* This line displays the error message */}
      <div className='google-button'>
      <GoogleButton className= "custom-google-button" onClick={handleGoogleSignIn}>Sign in with Google</GoogleButton>
      </div>
      <div className="separator">OR</div>
      <div className="auth-container">
        {renderAuthForm()}
      </div>
    </div>
  );
};

export default LoginRegistration;
