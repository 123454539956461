import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics"
import { getApp } from 'firebase/app';
import { getAuth, signOut, signInWithPopup, signInWithRedirect, GoogleAuthProvider,sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink ,updateProfile, EmailAuthProvider, signInWithEmailAndPassword,createUserWithEmailAndPassword,sendPasswordResetEmail } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import * as firebaseui from 'firebaseui';

const firebaseConfig = {
  apiKey: "AIzaSyDY9aUD1gHLeB3ukDf9DaWnw6LmBcMiXZU",
  authDomain: "habitify.app",
  projectId: "emometer1",
  storageBucket: "emometer1.appspot.com",
  messagingSenderId: "94381419286",
  appId: "1:94381419286:web:4f8205fd77cf5a10913e37",
  measurementId: "G-RS49FVZ51Q"
};

const actionCodeSettings = {
  handleCodeInApp: true,     
  dynamicLinkDomain: 'habitify.page.link',

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app); // Initialize Firebase Auth here
const provider = new GoogleAuthProvider();

const googleProvider = new GoogleAuthProvider(app);
const emailProvider = new EmailAuthProvider(app);

// Initialize the FirebaseUI Widget using Firebase.
const ui = new firebaseui.auth.AuthUI(auth);

export {actionCodeSettings, ui, auth, googleProvider,  updateProfile , emailProvider, signOut, getAuth, signInWithPopup,signInWithRedirect,sendSignInLinkToEmail,isSignInWithEmailLink, signInWithEmailLink, provider, app,db, httpsCallable,functions, signInWithEmailAndPassword, createUserWithEmailAndPassword , sendPasswordResetEmail};