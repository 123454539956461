import React, { useState, useEffect } from 'react';
import { Timestamp } from 'firebase/firestore';
import './Habithandle.css'; // Import your CSS file here
import { useSubmissionStatus } from './SubmissionStatusContext'; // Import the context hook
import SwipeableViews from 'react-swipeable-views';


const Habithandle = ({ handleCreateHabit, responseMessage, handleSave, closeModal }) => {
    const [title, setTitle] = useState(''); // New state for title
    const [days, setDays] = useState('30'); // New state for days
    const [mentalModel, setMentalModel] = useState('');
    const [plan, setPlan] = useState('');
    const [pitfalls, setPitfalls] = useState('');
    const [resources, setResources] = useState([]);
    const [summary, setsummary] = useState('');
    const [habitText, setHabitText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [isActive, setIsActive] = useState(true);
    const { isSubmitting, setIsSubmitting, isSubmissionComplete, setIsSubmissionComplete, planRecommendationComplete, setPlanRecommendationComplete } = useSubmissionStatus();
    const [createHabit, setCreateHabit] = useState(false);
    const [expandedSection, setExpandedSection] = useState('summary'); // Default to 'summary' being open
    const [attemptToSubmit, setAttemptToSubmit] = useState(false); // New state to track submission attempt
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const maxSteps = 2; // Since you have two screens to show
    const [formKey, setFormKey] = useState(Date.now());

    const totalSteps = 4; // Assuming you have 4 steps in your form
    // Function to toggle collapsible sections
    const handleToggleSection = (section) => {
        setExpandedSection(expandedSection !== section ? section : null);
    };

    // Function to handle title change
    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    // Function to handle days change
    const handleDaysChange = (event) => {
        setDays(event.target.value);
    };

    const handleFocus = () => {
        const element = document.getElementById("typing-placeholder");
        if (element) {
            element.style.display = 'none';
        }
    };

    useEffect(() => {
        if (isDataLoaded) {
            // Set `activeStep` to 1 to navigate to the second tab
            setActiveStep(1);
        }
    }, [isDataLoaded]); // Dependency array ensures this runs only when `isDataLoaded` changes


    const isEmpty = (value) => value.trim() === '';

    // Function to check if the required fields are empty
    const isFormValid = () => {
        return !isEmpty(title) && !isEmpty(days) && !isEmpty(summary);
    };

    // Function to handle the attempt to submit the form
    const handleAttemptToSubmit = () => {
        if (!isFormValid()) {
            setAttemptToSubmit(true); // Set the flag to true to trigger the animation
            setTimeout(() => setAttemptToSubmit(false), 1000); // Reset the flag after the animation duration
        } else {
            handleSubmit(); // Proceed with the submission if the form is valid
        }
    };
    const [placeholderText, setPlaceholderText] = useState('');
    useEffect(() => {
        const placeholders = [
            'I want to write a novel',
            'I would like to learn to meditate',
            'I want to learn to play guitar',
            // Add more placeholder suggestions
        ];
        let activePlaceholderIndex = 0;

        const updatePlaceholder = () => {
            setPlaceholderText(placeholders[activePlaceholderIndex]);
            activePlaceholderIndex = (activePlaceholderIndex + 1) % placeholders.length;
        };

        const intervalId = setInterval(updatePlaceholder, 3000); // Change placeholder every 3 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);


    useEffect(() => {
        console.log("Checking", responseMessage);

        if (responseMessage && responseMessage.feedback) {
            setTitle(responseMessage.feedback.Title || '');
            // Check if Days exists before calling toString()
            const daysString = responseMessage.feedback.Days ? responseMessage.feedback.Days.toString() : '';
            setDays(daysString);
            setMentalModel(responseMessage.feedback["Mental model"] || '');
            setPlan(responseMessage.feedback.Plan || '');
            setPitfalls(responseMessage.feedback["Pitfalls to avoid"] || '');
            setsummary(responseMessage.feedback.Summary || '');
            setActiveStep(1); // This line navigates to the second step
        }

    }, [responseMessage]);

    const handleRecommendPlanClick = () => {
        setIsSubmitting(true);
        setPlanRecommendationComplete(true);
        handleCreateHabit(habitText)
            .then(() => {
                setIsSubmitting(false);
                setPlanRecommendationComplete(false);
                setIsSubmissionComplete(true);
                setTimeout(() => {
                    setIsSubmissionComplete(false);
                    setPlanRecommendationComplete(false); // Reset after a delay
                }, 3000);
            })
            .catch(() => {
                setIsSubmitting(false); // handle error state as needed
            });
    };

    const hasAtLeastTwoWords = (text) => {
        return text.trim().split(/\s+/).length >= 2;
    };


    const handleSubmit = () => {
        setIsSubmitting(true);
        setCreateHabit(true);
        const habitData = {
            title,
            days,
            startDate,
            mentalModel,
            plan,
            pitfalls,
            isActive,
            timestamp: Timestamp.now(),
            lastStatusUpdated: Timestamp.now(),
            resources,
            summary,
        };

        handleSave(habitData)
            .then(() => {
   
                clearForm();
            })
            .catch((error) => {
                // Handle the error here
                console.error("Save failed:", error);
            })
            .finally(() => {
                setIsSubmitting(false);
                setCreateHabit(false);
                setShowSuccessMessage(true); // Show success message
                setTimeout(() => {
                    setShowSuccessMessage(false); // Hide success message after 2 seconds
                    closeModal();
                }, 2000);
            }
            );
    };

    const clearForm = () => {
        setTitle(''); setDays('30'); setStartDate(''); setMentalModel(''); setPlan(''); setPitfalls('');
        setIsActive(true); setResources([]); setHabitText(''); setsummary(''); setIsSubmissionComplete(false); setPlanRecommendationComplete(false);
        setFormKey(Date.now()); // Reset the key to force remount
        console.log("Cleared");
    };

    const [index, setIndex] = useState(0); // New state for tracking SwipeableViews index

    // Other existing functions and useEffects

    // Function to navigate to the next card
    const handleNext = () => {
        if (index < 3) { // Assuming there are 3 swipeable views
            setIndex(prevIndex => prevIndex + 1);
        }
    };

    // Function to navigate to the previous card
    const handlePrev = () => {
        if (index > 0) {
            setIndex(prevIndex => prevIndex - 1);
        }
    };



    return (

        <div key={formKey} className="habit-form-container">



            <div className="steps-indicator">
                {['Step 1: Start here', 'Step 2: Envision Your Success'].map((step, i) => (
    <button 
    key={i} 
    className={`step-indicator-button ${activeStep === i ? 'active' : ''}`}
    onClick={() => setActiveStep(i)} // This makes the indicators clickable
  >
    {step}
  </button>
                ))}
            </div>

            {/* SwipeableViews to handle the swiping between two screens */}
            <SwipeableViews index={activeStep} onChangeIndex={setActiveStep} className="swipeable-sections">


                <div className="habit-step">
                    <div className="step-header">
                        <span><b> Step 1: Enter a few words about your goal</b> </span>
                    </div>
                    <div className="habit-input-plan-container">
                        <textarea
                            className="habit-input"
                            value={habitText}
                            onChange={(e) => setHabitText(e.target.value)}
                            onFocus={handleFocus}
                            placeholder={placeholderText}
                            rows="2"
                        />
                        {isSubmitting && planRecommendationComplete ? (
                            <div className="loader"></div>  // Show loading icon
                        ) : isSubmissionComplete && !planRecommendationComplete ? (
                            <div className="submission-complete-icon">✓</div> // Show completion icon
                        ) : (
                            <div className="button-container">  <button className="recommend-plan-button" onClick={handleRecommendPlanClick}
                                disabled={!hasAtLeastTwoWords(habitText)}>
                                Generate Plan
                            </button> </div>
                        )}
                    </div> </div>


                <div className="habit-step">
                    <div className="review-step">
                        <div className="step-header">
                            <span><b>Step 2: Envision Your Success</b></span>                            
                        </div>
                        <div className="commitment-card">
                            <div className="title-container">
                                <input
                                    type="text"
                                    className="title-input"
                                    value={title}
                                    onChange={handleTitleChange}
                                    placeholder="Describe your goal in few words"
                                />
                            </div>
                            <div className="days-container">
                                <input
                                    type="number"
                                    className="days-input"
                                    value={days}
                                    onChange={handleDaysChange}
                                    placeholder="30"
                                />
                                <span className="days-text">days</span>
                            </div>
                        </div>
                    </div>


                    <div className="habit-step">
                    <div className="swipe-navigation">
  {['Summary', 'Mental Model', 'Plan', 'Pitfalls'].map((title, i) => (
    <div 
      key={i} 
      className={`swipe-step ${index === i ? 'active' : ''}`}
      onClick={() => setIndex(i)} // Set the index to the clicked step
    >
      {title}
    </div>
  ))}
</div>
                        <SwipeableViews index={index} onChangeIndex={setIndex} className="swipeable-sections">
                         
                              
                                <textarea
                                    className="habit-textarea"
                                    value={summary}
                                    onChange={(e) => setsummary(e.target.value)}
                                    placeholder="Summary"
                                />
                         

                           
                             
                                <textarea
                                    className="habit-textarea"
                                    value={mentalModel}
                                    onChange={(e) => setMentalModel(e.target.value)}
                                    placeholder="Mental Model"
                                />



                      

                           
                            
                                <textarea
                                    className="habit-textarea"
                                    value={plan}
                                    onChange={(e) => setPlan(e.target.value)}
                                    placeholder="Plan"
                                />



                      
                                                  

                                <textarea
                                    className="habit-textarea"
                                    value={pitfalls}
                                    onChange={(e) => setPitfalls(e.target.value)}
                                    placeholder="Pitfalls to Avoid"
                                />
                         


                        </SwipeableViews>



                    </div>



                    <input
                        className="habit-date-input"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder="Start Date"
                        style={{ display: 'none' }} // This hides the input
                    />


                    <label>
                        <input
                            className="habit-checkbox"
                            type="checkbox"
                            checked={isActive}
                            onChange={(e) => setIsActive(e.target.checked)}
                            style={{ display: 'none' }} // This hides the input
                        />
                    </label>



                 



                        {isSubmitting && createHabit ? (
                            <div className="loader"></div> // Show loading icon
                        ) : isSubmissionComplete && createHabit ? (
                            <div className="submission-complete-icon">✓</div> // Show completion icon
                        ) : (
                            <div className="button-container"> <button
                                className="habit-save-button"
                                disabled={!isFormValid()}
                                onClick={() => {
                                    handleAttemptToSubmit(); // Call your handleSave function
                                    setPlanRecommendationComplete(false); // Reset the plan recommendation complete state
                                }}
                            >
                                Save this plan
                            </button> </div>
                        )}

             
                </div>
            </SwipeableViews>
            {showSuccessMessage && (
                <div className="success-popup">
                    Habit plan created successfully!
                </div>
            )}
        </div>
    );
};
export default Habithandle;
