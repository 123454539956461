import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMicrophone,faStop,faAnglesDown, faAnglesUp, faAngleDoubleUp, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { useSubmissionStatus } from './SubmissionStatusContext'; // Import the context hook
import './Habitprogress.css';
import SwipeableViews from 'react-swipeable-views';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import EventBus from '../EventBus';
import MyProgress from './MyProgress'; // Adjust the path according to your file structure




const HabitProgress = ({ userId, fetchHabitTitles, fetchHabitDetails, fetchProgressUpdates, addProgressUpdate,habitTitles_default, generateResponseProgress, handleMicClick, addstatusUpdate }) => {
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [habitTitles, setHabitTitles] = useState([]);
  const [habitFeedback, setHabitFeedback] = useState([]);
  const [Plan, setPlan] = useState([]);
  const [Title, setTitle] = useState([]);
  const [Mentalmodel, setMentalmodel] = useState([]);
  const [Pitfalls, setPitfalls] = useState([]);
  const [carouselItems, setCarouselItems] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
const [statusUpdateMessage, setStatusUpdateMessage] = useState('');
 const [TranscriptionStarted, setTranscriptionStarted] = useState('');

 


  const [selectedHabitId, setSelectedHabitId] = useState('');
  const [progressNote, setProgressNote] = useState('');
  const [progressUpdates, setProgressUpdates] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(false);

  const [responseMessage, setResponseMessage] = useState(null);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [showAnalyticsPopup, setshowAnalyticsPopup] = useState(false);


  const { isSubmitting, setIsSubmitting, isSubmissionComplete, setIsSubmissionComplete } = useSubmissionStatus();
  const [progressMessage, setprogressMessage] = useState("");
  const [transcribedText, setTranscribedText] = useState('');
  const [latestUpdateDate, setLatestUpdateDate] = useState('');
  const [isaudioerror, setisaudioerror] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(true);


 

  const [index, setIndex] = useState(0); // New state for tracking SwipeableViews index

  const location = useLocation();
  const getQueryParamValue = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('docid'); // Note: Query parameter keys are case-sensitive
};

const [docId, setDocId] = useState('');

  const handleNext = () => {
      if (index < 3) { // Assuming there are 3 swipeable views
          setIndex(prevIndex => prevIndex + 1);
      }
  };

  function renderFeedback(feedback) {
    // Convert feedback object to a string or a JSX element
    // Make sure to return appropriate JSX based on your data structure
    // This is a placeholder implementation
    return feedback && Object.entries(feedback).map(([key, value]) => (
      <div key={key}>
        <strong>{key.replace(/_/g, ' ')}:</strong> {value}
      </div>
    ));
  }

  const handleCheckboxChange = async () => {
    if (!userId || !selectedHabitId) {
      console.error("Missing userId or selectedHabitId");
      return;
    }
  
    const result = await addstatusUpdate(userId, selectedHabitId, "complete");
    if (result.success) {
      setStatusUpdateMessage(result.message);
      setIsChecked(true); // Disable the checkbox as it's now checked
    } else {
      // Handle the error case, possibly by showing an error message to the user
      console.error(result.message);
    }
  };
  
  
// Automatic slide to the latest item when new data is added
useEffect(() => {
  const autoSlideTimeout = setTimeout(() => {
    if (carouselItems.length > 0) {
      setIndex(carouselItems.length - 1); // Set the index to the latest item
    }
  }, 300); // Set this to how long you want to wait before sliding

  return () => clearTimeout(autoSlideTimeout); // Clean up the timeout
}, [carouselItems.length]);


    // Update the carousel items when new data is available
    useEffect(() => {
        // A helper function to check if the item key already exists
  const itemKeyExists = (key) => carouselItems.some(item => item.key === key);

      const newItems = [...carouselItems]; // Start with current items

       if (latestUpdateDate && !itemKeyExists('latestUpdateDate')) {
        newItems.push({ content: `        
        Your last conversation with Habit Coach was on ${latestUpdateDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}, ${latestUpdateDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }).toLowerCase()}`, key: 'latestUpdateDate' });
      }
      if (transcribedText && !itemKeyExists('transcribedText')) {
        newItems.push({ content: transcribedText, key: 'transcribedText' });
      }
      if (progressMessage && progressMessage.feedback && !itemKeyExists('feedback')) {
        newItems.push({ content: renderFeedback(progressMessage.feedback), key: 'feedback' });
      }
        // Only update the state if there are changes
  if (newItems.length !== carouselItems.length) {
    setCarouselItems(newItems);
  }
}, [latestUpdateDate, transcribedText, progressMessage, carouselItems]);

  useEffect(() => {
    const refreshData = () => {
        // Increment the trigger to re-run data fetching
        setRefreshTrigger(prev => prev + 1);
    };

    // Subscribe to the relevant events
    EventBus.subscribe('habitDeleted', refreshData);
    EventBus.subscribe('habitCreatedOrUpdated', refreshData);

    // Cleanup function to unsubscribe if needed
    return () => {
        EventBus.unsubscribe('habitDeleted', refreshData);
        EventBus.unsubscribe('habitCreatedOrUpdated', refreshData);
    };
}, []);

  useEffect(() => {
    const docIdValue = getQueryParamValue();
    if (docIdValue) {
        setDocId(docIdValue);
        setSelectedHabitId(docIdValue);
        console.log("Captured docId from query:", docIdValue);
        // You can now use docIdValue for fetching data or other logic
    }
}, [location.search]); // React to changes in the query string
  // Function to navigate to the previous card

  const handlePrev = () => {
      if (index > 0) {
          setIndex(prevIndex => prevIndex - 1);
      }
  };

  const handlePrev_main = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : carouselItems.length - 1));
  };
  
  const handleNext_main = () => {
    setIndex((prevIndex) => (prevIndex < carouselItems.length - 1 ? prevIndex + 1 : 0));
  };

  

  const toggleFeedbackPopup = () => {
    setShowFeedbackPopup(!showFeedbackPopup);
  };

  const toggleAnalyticsPopup = () => {
    setshowAnalyticsPopup(!showAnalyticsPopup);
  };

    // Function to toggle showing all habits
    const toggleKeyboard = () => {
      setShowKeyboard(!showKeyboard);
    };
 

    const startRecording = () => {
      setIsRecording(true);
      setprogressMessage(null);
      setTranscribedText(null);
      setLatestUpdateDate(null);
      setCarouselItems([]); // Clear existing carousel items
      setShowInitialMessage(false); // Hide initial message and show carousel data
      handleMicClick(true, updateTranscribedText, onTranscriptionComplete); // Start recording

     
    };
    
    const stopRecording = () => {
      setIsRecording(false);
      setCarouselItems([]); // Clear existing carousel items
      handleMicClick(false, updateTranscribedText, onTranscriptionComplete); // Stop recording     
    };

    const updateTranscribedText = (text) => {
      setProgressNote(text); // Update the progress note
    };

    const onTranscriptionComplete = (transcribedText) => {
      if (userId && selectedHabitId && transcribedText && habitFeedback) {
        setTranscribedText(transcribedText);
        handleAddProgress(transcribedText); // Submit the form with transcribed text
        console.log ("la la la lal ", transcribedText)
      }
    };
  
  
    useEffect(() => {
      console.log("Checking-->");
      let isMounted = true; // Declare isMounted flag  
    
      if (userId) {
        fetchHabitTitles().then(titles => {    
          if (isMounted) {
            setHabitTitles(titles); // Use 'titles' directly since it's already sorted
            if (titles.length > 0) {
              if (!selectedHabitId && !docId) {
                setSelectedHabitId(titles[0].id); // Use 'titles[0]' directly
                if (titles[0].lastUpdated && titles[0].lastUpdated.toDate) {
                  setLatestUpdateDate(titles[0].lastUpdated.toDate());
                } else {
                  setLatestUpdateDate(new Date());
                }
              }
            }
          }
        });
        

      }
      return () => {
        isMounted = false; // Set isMounted to false when the component unmounts
      };
     
    }, [userId, docId, refreshTrigger]);
        
    
  useEffect(() => {
    console.log("Checking");
    if (userId && selectedHabitId && showAnalyticsPopup) {
      fetchProgressUpdates(userId, selectedHabitId).then(setProgressUpdates);
     
    }
  }, [userId, selectedHabitId, fetchProgressUpdates, showAnalyticsPopup]);

  useEffect(() => {
    console.log("Isbumitting Value", isSubmitting);
  }, [isSubmitting]);

  useEffect(() => {

    console.log("Test");
  });

  useEffect(() => {
   
    const selectedHabit = habitTitles.find(habit => habit.id === selectedHabitId);
    console.log("Checking", selectedHabit);
    if (selectedHabit) {
      setHabitFeedback(selectedHabit.summary);
      setPlan(selectedHabit.plan);
      setTitle(selectedHabit.title);
      setMentalmodel(selectedHabit.mentalModel);
      setPitfalls(selectedHabit.pitfalls);
      if (selectedHabit.lastUpdated?.toDate) {
        setLatestUpdateDate(selectedHabit.lastUpdated.toDate());
      }
    }
  }, [selectedHabitId, habitTitles]); // Add this useEffect to update feedback when selected habit changes

  useEffect(() => {
    console.log("Checking");
    let feedbackOutput = '';

    if (responseMessage) {
        if (typeof responseMessage === 'object' && responseMessage.feedback) {
            // Directly use the value of 'feedback' key if it exists
            feedbackOutput = responseMessage.feedback;
        } else if (typeof responseMessage === 'string') {
            // If the responseMessage is a string, use it directly
            feedbackOutput = responseMessage;
        }

        setResponseMessage(feedbackOutput);
    }
}, [responseMessage]);

const jsonToHtml = (json) => {
  let html = "";
  try {
    // Assuming `json` is either a JSON string or object
    const obj = typeof json === "string" ? JSON.parse(json) : json;

    // Generate HTML content
    html = Object.entries(obj).map(([key, value]) => {
      // For nested objects, recursively convert them to HTML
      if (typeof value === "object" && value !== null) {
        return `<div><strong>${key}:</strong><div style="padding-left: 20px;">${jsonToHtml(value)}</div></div>`;
      } else {
        // For simple key-value pairs, generate a simple line
        return `<div><strong>${key}:</strong> ${value}</div>`;
      }
    }).join("");
  } catch (error) {
    console.error("Error converting JSON to HTML:", error);
    html = "Failed to parse message content.";
  }
  return html;
};

const handleAddProgress = async (transcribedText) => {
  console.log("Post Transcribing now");
  setTranscribedText(transcribedText);
  setShowKeyboard(false); // Close the popup
  setIsSubmitting(true); // Indicate submission start
  if (userId && selectedHabitId && transcribedText && habitFeedback) {
   
    
    addProgressUpdate(userId, selectedHabitId, transcribedText, habitFeedback, 'In Progress', generateResponseProgress)
      .then((response) => {
        setIsSubmitting(false); // Indicate submission end
        fetchProgressUpdates(userId, selectedHabitId).then(setProgressUpdates);
        setProgressNote('');
       
        console.log("Response from addProgressUpdate:", response); // Log the response
        setprogressMessage(response);
        
      })
      .catch((error) => {
        console.error("Error in handleAddProgress:", error); // Log the error
        setIsSubmitting(false); // Indicate submission end on error
        setprogressMessage("Failed to update progress.");
      });
  }
};

  // New function to navigate to the next habit
  const navigateHabits = (direction) => {
    const currentIndex = habitTitles.findIndex(habit => habit.id === selectedHabitId);
    let newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

    // Loop back if navigating beyond the start or end of the array
    if (newIndex < 0) {
      newIndex = habitTitles.length - 1;
    } else if (newIndex >= habitTitles.length) {
      newIndex = 0;
    }

    setSelectedHabitId(habitTitles[newIndex].id);
  };

  function renderFeedback(feedback) {
    return Object.entries(feedback).map(([key, value]) => (
      <div key={key}>
        <strong>{key.replace(/_/g, ' ')}:</strong> {value}
      </div>
    ));
  }

  useEffect(() => {
    
    const handleRecordingStarted = () => {
      console.log("Logic to handle UI changes when recording starts");
      setisaudioerror(false); // End submitting
    };
  
    const handleRecordingStopped = () => {
      console.log("Logic to handle UI changes when recording stops");      
    };
  
    const handleTranscriptionStarted = () => {
      console.log("Logic to handle UI changes when handleTranscriptionStarted"); 
      setTranscriptionStarted(true);
    };
  
    const handleTranscriptionCompleted = () => {
      console.log("Logic to handle UI changes when handleTranscriptionCompleted");   
      setTranscriptionStarted(false);
    };
    const audioerror = () => {
      console.log("Logic to handle UI changes when audioerror");  
      setisaudioerror(true); // End submitting
    };
  
    EventBus.subscribe('recordingStarted', handleRecordingStarted);
    EventBus.subscribe('recordingStopped', handleRecordingStopped);
    EventBus.subscribe('transcriptionStarted', handleTranscriptionStarted);
    EventBus.subscribe('transcriptionCompleted', handleTranscriptionCompleted);
    EventBus.subscribe('audioerror', audioerror);

    
  
    return () => {
      EventBus.unsubscribe('recordingStarted', handleRecordingStarted);
      EventBus.unsubscribe('recordingStopped', handleRecordingStopped);
      EventBus.unsubscribe('transcriptionStarted', handleTranscriptionStarted);
      EventBus.unsubscribe('transcriptionCompleted', handleTranscriptionCompleted);
      EventBus.unsubscribe('audioerror', audioerror);
    };
  }, []);
  


  return (
    <>
    <div className="habit-progress-form">
      <div className="dropdown-and-info"> 
      <div className="dropdown-row-wrapper">    
      <div className="dropdown-container">
      <select 
  value={selectedHabitId} 
  onChange={(e) => {
    setSelectedHabitId(e.target.value);
    setIsChecked(false); // Re-enable the checkbox
    // Reset any related states if necessary, e.g., statusUpdateMessage
 
  }}
>

          {habitTitles.map((habit, index) => (
            <option key={index} value={habit.id}>{habit.title}</option>
          ))}
          </select>
          </div>
      <div className='mic-keyboard-row'>
      {/* <div className="last-updated">
  <span>Your last update was on </span>
  {latestUpdateDate && latestUpdateDate instanceof Date ? (
    <>
      {latestUpdateDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })},
      {latestUpdateDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }).toLowerCase()}
    </>
  ) : (
    <span>Loading date...</span>
  )}
</div> */}
    <div onClick={toggleFeedbackPopup} className='progress-button'>
      <span class="material-symbols-outlined">info</span><span className="icon-label">Goal Info</span>
    </div>
    <div onClick={toggleAnalyticsPopup} className='progress-button'>
      <span class="material-symbols-outlined">analytics</span> <span className="icon-label">My Progress</span>
    </div>
    </div>
 
  </div>

{/* Conditionally render messages */}
{isRecording ? (
  <span>Recording in progress...</span>
) : TranscriptionStarted ? (
  <div className="sending-message-to-coach">
    <span>Sending message to Coach...</span>
  </div>
) : transcribedText || (progressMessage && progressMessage.feedback) ? (
  <>
    {progressMessage && progressMessage.feedback && (
      <div className="coach-text">
        {renderFeedback(progressMessage.feedback)}
      </div>
    )}
    {transcribedText && (
      <div className={`input-key-transcription-text ${progressMessage && progressMessage.feedback ? 'with-feedback' : ''}`}>
        <span><strong>You said:</strong> {transcribedText}</span>
      </div>
    )}
  </>
) : (
  <div className="guide">
    <div className='guidingText-large'>Press and hold the button</div>
    <div className="guidingText">Share your progress or challenges, and release to send.</div>
    <div className="guideUser">
      <FontAwesomeIcon icon={faAngleDoubleDown} />
    </div>
  </div>
)}



      </div> 







            
      {showFeedbackPopup && (
             
        <div class="popup-background" onClick={toggleFeedbackPopup}>
          <div className="popup">
          <div class="popup-title">{Title}</div>
          <div className="popup-content" onClick={e => e.stopPropagation()}>     
            
            <div className="popup-content">
            <SwipeableViews index={index} onChangeIndex={setIndex} className="swipeable-sections">
            <div className="card">
            <div className="card-header">Summary</div>      {habitFeedback} 
            </div>
            <div className="card">
            <div className="card-header">Plan</div>
            {Plan}
            </div>
            <div className="card">
            <div className="card-header">Mental Model</div>
            {Mentalmodel}
            </div>
            <div className="card">
            <div className="card-header">Pitfalls to Avoid</div>
            {Pitfalls}
            </div>
              
              </SwipeableViews>
              
               </div>
        
          </div>          
          <div class="popup-footer"></div>
   </div>
        </div>
      )}


{showAnalyticsPopup && (
  <MyProgress progressUpdates={progressUpdates} onClose={toggleAnalyticsPopup} />
)}

 {showKeyboard && (
          <div class="popup-background" onClick={toggleKeyboard}>
          <div class="popup" onClick={e => e.stopPropagation()}>
          <div class="popup-title">Add your progress here!</div>
          <div className="popup-content" >
  {!isSubmitting && responseMessage ? (
    <div>{responseMessage}</div>
  ) : (
    <div>
      <textarea 
        value={progressNote}  
        onChange={(e) => setProgressNote(e.target.value)}
        placeholder="Enter your progress here using keyboard instead." 
      />
                  <button 
              onClick={() => handleAddProgress(progressNote)}
              disabled={!progressNote.trim()} // Disable button if progressNote is empty or only contains whitespace
            >
              Add Progress
            </button>

    </div>
  )}
</div>
   </div>
        </div>
      )} 
    </div>
    <div className="progress-form">
    <div onClick={toggleKeyboard} className="keyboard-icon">
      <span className="material-symbols-outlined">keyboard</span>
    </div>   

    <div className="mic-controls">
      {isSubmitting && !isaudioerror ? (
  <div className="icon-container">
                     <div className="loader"></div>  
                     </div>
                ) : isSubmissionComplete ? (
                  <div className="icon-container">
                    <div className="submission-complete-icon">✓</div>
                    </div>
                ) : (<div className="mic-button"   onMouseDown={startRecording}  
                     onMouseUp={stopRecording}   onTouchStart={startRecording}  
                     onTouchEnd={stopRecording} >
                    <FontAwesomeIcon icon={isRecording ? faStop : faMicrophone} className="mic-icon" />
                    </div>)
                    
                    }  

               
   </div>  

  {/* Checkbox and Keyboard Icon */}
  <div className='keyboard-checkbox-container'>

    <div className="checkbox-container">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        disabled={isChecked}
      />
      <label>Mark as Complete</label>
      {statusUpdateMessage && <small>{statusUpdateMessage}</small>}
    </div>

  </div>


    </div>
    </>
  );
};

export default HabitProgress;
