import React from 'react';
import './Testimonials.css'; // Ensure you have this CSS file

const testimonials = [
  {
    stars: "⭐️⭐️⭐️⭐️⭐️",
    review: "Habitify has completely transformed my daily routines! The AI's personalized habit suggestions have helped me build a morning ritual that energizes my day. It's like having a personal coach who knows exactly what I need.",
    reviewer: "Jordan M.",
    status: "✅ Verified User",
  },
  {
    stars: "⭐️⭐️⭐️⭐️⭐️",
    review: "With Habitify, tracking my progress has never been easier or more insightful. The AI-driven insights highlight what's working and where I can improve, making habit formation feel like a guided journey towards my best self.",
    reviewer: "Alex T.",
    status: "✅ Verified User",
  },
  {
    stars: "⭐️⭐️⭐️⭐️⭐️",
    review: "I've never felt more motivated to stick with my habits! Habitify's daily motivational boosts and the supportive community have been game-changers for me, keeping me on track and inspired every day.",
    reviewer: "Sara P.",
    status: "✅ Verified User",
  },
];

function Testimonials() {
  return (
    <div className="testimonials-container">
      {testimonials.map((testimonial, index) => (
        <div className="testimonial" key={index}>
          <div className="stars">{testimonial.stars}</div>
          <p>{testimonial.review}</p>
          <p><strong>{testimonial.reviewer}</strong><span className="verified">{testimonial.status}</span></p>
        </div>
      ))}
    </div>
  );
}

export default Testimonials;
