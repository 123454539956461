// MyProgress.js
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Default styling
import './MyProgress.css'; // Your custom styles

const MyProgress = ({ progressUpdates, onClose }) => {
  const [value, setValue] = useState(new Date());

  const findActivitiesForDate = (date) => {
    const activitiesOnDate = progressUpdates.filter((update) =>
      new Date(update.date.toDate()).toDateString() === date.toDateString()
    );
    return activitiesOnDate;
  };

  return (
    <div className="popup-background" onClick={onClose}>
      <div className="popup" onClick={(e) => e.stopPropagation()}>
        <div className="popup-title">Progress History</div>
        <div className="popup-content">


<div className="calendar-content">
<Calendar
        onChange={setValue}
        value={value}
        tileClassName={({ date, view }) => {
          if (view === 'month') {
            if (date > new Date()) {
              return 'future-date'; // Apply greyed-out style
            }
            const activities = findActivitiesForDate(date);
            if (activities.length > 0) {
              return 'activity-day'; // Class when activity is recorded
            }
            // Check if the date is within the last 30 days
            const today = new Date();
            const thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30));
            if (date >= thirtyDaysAgo && date <= new Date()) {
              return 'no-activity-day'; // Class when no activity is recorded in the last 30 days
            }
          }
          return '';
        }}
        
// inside your MyProgress component

tileContent={({ date, view }) => {
  if (view === 'month') {
    const activities = findActivitiesForDate(date);
    // If there are activities for this date
    if (activities.length > 0) {
      // Check if the activities are more than 3
      if (activities.length > 3) {
        return (
          <div className="activities-indicator">
            <span className="activities-count">{activities.length}</span>
            <span className="dot green-dot"></span> {/* Green dot symbol */}
          </div>
        );
      } else {
        // If activities are 3 or less, show individual green dots for each
        return (
          <div className="activities-indicator">
            {activities.map((_, index) => (
              <span key={index} className="dot green-dot"></span> // Green dot for each activity
            ))}
          </div>
        );
      }
    } else {
      const today = new Date();
      const thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30));
      if (date >= thirtyDaysAgo && date <= new Date()) {
        return (
          <div className="activities-indicator">
            <span className="dot red-dot"></span> 
          </div>
        );
      }
    }
  }
}}



  tileDisabled={({ date, view }) => {
    // Disable tiles for future dates
    return date > new Date();
  }}
  
      />
</div>
        </div>
        <div className="popup-footer"></div>
      </div>
    </div>

  );
};

export default MyProgress;
