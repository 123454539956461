import React, { useState, useEffect } from 'react';
import Habithandle from './Habithandle'; // Import the Habithandle component
import ManageHabits from './ManageHabits'; 
import logoImage from '../components/images/logo_habitify.png';
import './header.css';
import Pricing from '../Pricing';
import EventBus from '../EventBus'; 


function Header({ user, handleSignIn, handleSignOut, handleCreateHabit, responseMessage, handleSave, fetchHabitTitles, deleteHabit}) {
    const displayName = user && user.displayName 
    ? (user.displayName.trim().includes(' ') ? user.displayName.split(' ')[0] : user.displayName)
    : 'Guest';

    const [showHabitForm, setShowHabitForm] = useState(false);
    const [showManageHabits, setShowManageHabits] = useState(false); // New state for ManageHabits visibility
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track menu open status
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);


    useEffect(() => {
        const handleHabitDeleted = (status) => {
          if (status) {
            setShowManageHabits(false);
          }
        };
    
        // Subscribe to the habitDeleted event
        EventBus.subscribe('habitDeleted', handleHabitDeleted);
    
        // Unsubscribe on cleanup
        return () => {
          EventBus.unsubscribe('habitDeleted', handleHabitDeleted);
        };
      }, []); // Empty dependency array ensures this runs once on mount and once on unmount
    

    const toggleHabitForm = () => setShowHabitForm(!showHabitForm);
    const handleCloseModal = () => {setShowHabitForm(false);};
    const handleCloseModal_delete = () => {setShowManageHabits(false);};
    const toggleUpgradePopup = () => setShowUpgradePopup(!showUpgradePopup);


        // New function to toggle Manage Habits visibility
        const toggleManageHabits = () => {
            setShowManageHabits(!showManageHabits);
            if (isMenuOpen) setIsMenuOpen(false); // Close mobile menu if open
        };
        const toggleMenu = () => {
            setIsMenuOpen(!isMenuOpen);
          };


          useEffect(() => {
            const handleOutsideClick = (event) => {
                // Ensure that clicks on the burger menu do not close the menu
                if (!event.target.closest('.burger-menu') && isMenuOpen) {
                    setIsMenuOpen(false);
                }
            };
        
            // Since we want to detect clicks outside, we need to add the event listener to the whole document
            document.addEventListener('click', handleOutsideClick);
        
            // Cleanup function to remove event listener
            return () => document.removeEventListener('click', handleOutsideClick);
        }, [isMenuOpen]); // Dependency array ensures effect runs only when isMenuOpen changes
        
    


    return (
        <div className="header">
            <div className='header-inner' >
                <div className='logo-container'>
                <div className='logo'><img src={logoImage} alt="logo" /></div>
          <div className="burger-menu lg:hidden" onClick={toggleMenu}>
            <span className="material-symbols-outlined">{isMenuOpen ? 'close' : 'menu'}</span>
          </div>
        </div>
        {isMenuOpen && <div className="menu-overlay" onClick={toggleMenu}></div>} {/* Overlay */}
        
            
            {showHabitForm && (
                <div className="manage-habits-popup">
                        <Habithandle 
                            handleCreateHabit={handleCreateHabit}
                            responseMessage={responseMessage}
                            handleSave={handleSave}
                            closeModal={handleCloseModal}                            
                        />
            <div className="popup-overlay" onClick={toggleHabitForm}></div>
                </div>
            )}

{showManageHabits && (
    <div className="manage-habits-popup">
        <ManageHabits 
            userId={user} 
            fetchHabitTitles={fetchHabitTitles}
            onClose={() => setShowManageHabits(false)} // Pass an onClose prop
            closeModal_delete={handleCloseModal_delete}
            deleteHabit ={deleteHabit}
        />
        <div className="popup-overlay" onClick={() => setShowManageHabits(false)}></div>
    </div>
)}


{showUpgradePopup && (
    <div className="manage-habits-popup">

<Pricing userEmail={user?.email} 
onClose={() => setShowUpgradePopup(false)}
/>
<div className="popup-overlay" onClick={() => setShowUpgradePopup(false)}></div>
</div>
)}


            <nav className="navigation">
          {/* Mobile Menu */}
          <div className={`mobile-menu ${isMenuOpen ? '' : 'hidden'} lg:hidden`}>
            <div>Hello, {displayName}</div>
            <div onClick={toggleHabitForm}>Create Habit</div>
            {/* <div onClick={toggleUpgradePopup}>Upgrade</div> */}
            <div onClick={toggleManageHabits}>Manage Habits</div>           
            {/* <div>Account</div> */}
            {user ? (
                                    <div onClick={handleSignOut}>Logout</div>
                                ) : (
                                    <div onClick={handleSignIn}>Login</div>
                                )}
          </div>

                    {/* Desktop Menu */}
                    <div className="desktop-menu hidden lg:flex">
                    <div className="dropdown">
                            <span>Habits</span>
                            <div className="dropdown-content">
                                <div onClick={toggleHabitForm}>Create Habit</div>
                                <div onClick={toggleManageHabits}>Manage Habits</div>
                              
                            </div>
                        </div>
                        <div className="dropdown">
                            <span>Hello, {displayName}</span>
                            <div className="dropdown-content">
                                {/* <div>Account</div> */}
                                {/* <div onClick={toggleUpgradePopup}>Upgrade</div> */}

                                {user ? (
                                    <div onClick={handleSignOut}>Logout</div>
                                ) : (
                                    <div onClick={handleSignIn}>Login</div>
                                )}
                            </div>
                        </div>

                        
                    </div>
                </nav>

            </div>  
            <hr className="header-divider" />
        </div>
        
    );
}

export default Header;
