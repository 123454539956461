import React, { useState, useEffect } from 'react';
import './hero.css'; // Make sure to define your styles in hero.css
import { ReactComponent as LaurelSVG } from './images/laurel.svg'; 
import { ReactComponent as StarSVG } from './images/stars.svg'; 
import Lottie from 'react-lottie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faMicrophone,faLightbulb , faEnvelope, faChartSimple} from '@fortawesome/free-solid-svg-icons';
import logoImage from '../components/images/logo_habitify.png';
import '../components/header.css';



function Hero() {

  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track menu open status



  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  
  useEffect(() => {
    const handleOutsideClick = (event) => {
        // Ensure that clicks on the burger menu do not close the menu
        if (!event.target.closest('.burger-menu') && isMenuOpen) {
            setIsMenuOpen(false);
        }
    };
  
    // Since we want to detect clicks outside, we need to add the event listener to the whole document
    document.addEventListener('click', handleOutsideClick);
  
    // Cleanup function to remove event listener
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isMenuOpen]); // Dependency array ensures effect runs only when isMenuOpen changes

  const handleButtonClick = () => {
    window.location.href = `${window.location.origin}/app`;
  };
  

  return (
    <>



    <section className="hero-section">      
        <div className="text-content"> 
        <div className="laurel-container">
        <div className="laurel-svg">
        <LaurelSVG />           
        </div>
        <span className="laurel-text">#1 Habit App</span>
        <div className="stars-container">
              {[...Array(5)].map((_, index) => (
                <StarSVG key={index} className="star" />
              ))}
            </div>

    </div>

          <div className='maintext'>Transform Your Daily Routines into Winning Habits with Your AI Companion!</div>
          <p className="subtitle">Unleash Your Potential Every Day. Build Lasting Habits with Personalized AI Insights!</p>
          <ul className="features-list">
            <li><FontAwesomeIcon icon={faBullseye}  style={{color: "#ffa742",}} />Tailored habits designed for your unique goals and preferences.</li>
            <li><FontAwesomeIcon icon={faChartSimple} style={{color: "#ffa742",}}/> Real-time tracking to celebrate your progress and stay motivated.</li>       
            <li><FontAwesomeIcon icon={faMicrophone} style={{color: "#ffa742",}} />Speak Your Progress: Record daily updates and get AI-powered feedback tailored to you.</li>
            <li> <FontAwesomeIcon icon={faLightbulb} style={{color: "#ffa742",}}/> AI-generated insights offering strategies to enhance habit formation.</li>
            <li> <FontAwesomeIcon icon={faEnvelope} style={{color: "#ffa742",}}/>Daily motivational emails to inspire consistency and commitment.</li>     
          </ul>
        
          <div className="button-container">
  <a href={`${window.location.origin}/app`} className="hero-button">
    Begin Your Success Story Today!
  </a>
</div>
          

      </div>

    </section>
    </>
  );
}

export default Hero;
