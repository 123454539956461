import React from 'react';
import Banner from './components/Banner';
import Hero from './components/Hero';
import Carousel from './components/Carousel';
import Reviews from './components/Reviews';
import Testimonials from './components/Testimonials.js';
import FAQ from './components/FAQ.js';
import Header from './components/header.css';
import logoImage from './components/images/logo_habitify.png';



function LandingPage() {
 
return (
    <div>
      
        <Banner/>
        <div className="header">
            <div className='header-inner' >
                <div className='logo-container'>
                <div className='logo'><img src={logoImage} alt="logo" /></div>
                </div>
                
                <div className="desktop-menu hidden lg:flex">                                          

                            <a href={`${window.location.origin}/app`} className="hero-button">
                            My Habits </a>

                            </div>
                
                </div></div>
        <Hero/>
        <Carousel/>
        <Reviews />
        <FAQ />
      <Testimonials />
     
      
    </div>
)
}
export default LandingPage;