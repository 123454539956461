import React from 'react';

function Banner() {
  return (
   <>
    <div class="banner small-screen">
        <p>Founded in Canada. We respect your privacy.</p>
    </div>
    
   
    <div class="banner medium-screen">
    <p>Founded in Canada<span class="material-symbols-outlined" >favorite</span>. We respect your privacy.</p>
    </div>
    
 
    <div class="banner large-screen">
    <p>Founded in Canada<span class="material-symbols-outlined" >favorite</span>. We respect your privacy.</p>
    </div>
    </>
  );
}

export default Banner;
