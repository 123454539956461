import React, { createContext, useState, useContext } from 'react';

const SubmissionStatusContext = createContext();

export const useSubmissionStatus = () => useContext(SubmissionStatusContext);

export const SubmissionStatusProvider = ({ children }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmissionComplete, setIsSubmissionComplete] = useState(false);
    const [planRecommendationComplete, setPlanRecommendationComplete] = useState(false);

    return (
        <SubmissionStatusContext.Provider value={{ isSubmitting, setIsSubmitting, isSubmissionComplete, setIsSubmissionComplete, planRecommendationComplete,setPlanRecommendationComplete  }}>
            {children}
        </SubmissionStatusContext.Provider>
    );
};


