import React from 'react';
import './Carousel.css';

function Carousel() {
  return (
    <div className="carousel-form">  
    <div className="carousel-container">
        <div className="carousel-slide">
            <div className="carousel-item">
            <span class="material-symbols-outlined">notifications</span>
                <p>Track habits </p>
            </div>
            <div className="carousel-item">
            <span class="material-symbols-outlined">lightbulb</span>
                <p>Discover new habits</p>
            </div>
            <div className="carousel-item">
            <span class="material-symbols-outlined">point_scan</span>
                <p>Goal Setting</p>
            </div>
            <div className="carousel-item">
            <span className="material-symbols-outlined">potted_plant</span>
                <p>Grow every day </p>
            </div>
            <div className="carousel-item">
            <span class="material-symbols-outlined">rocket_launch</span>
                <p>Proven strategies</p>
            </div>
            <div className="carousel-item">
            <span class="material-symbols-outlined">person_alert</span>
                <p>Avoid Pitfalls</p>
            </div>
            {/* Repeat items for a seamless loop */}
            <div className="carousel-item">
            <span class="material-symbols-outlined">point_scan</span>
                <p>Track habits </p>
            </div>

        </div>
    </div>
    </div>
  );
}

export default Carousel;
