import React, { useState } from 'react';
import './FAQ.css';
// Assuming the SVG is saved in the same directory
//import FaqImage from './faq-image.svg'; 

const faqData = [
  { question: "How does Habitify's AI-powered habit formation work?", answer: "Habitify uses advanced AI algorithms to analyze your lifestyle, routines, and goals to suggest personalized habits. Our system adapts to your progress, offering strategies to overcome challenges and ensure long-term success." },
  { question: "What makes Habitify different from other habit trackers?", answer: "Unlike traditional habit trackers, Habitify leverages AI to provide dynamic habit plans, personalized insights, and motivational boosts exactly when you need them, making habit formation more effective and engaging." },
  { question: "Can I track multiple habits at once with Habitify?", answer: "Yes, Habitify allows you to track multiple habits simultaneously, offering a comprehensive view of your progress across different areas of your life." },
  { question: "How does Habitify ensure my data privacy?", answer: "Your privacy is our top priority. Habitify uses encryption to protect your data and does not share personal information without your explicit consent." },
  { question: "Is there a community or support system for Habitify users?", answer: "Yes, Habitify has a supportive community where users can share experiences, tips, and encouragement. We also offer personalized coaching sessions." },
  { question: "Can Habitify help me break bad habits?", answer: "Absolutely. Habitify not only helps you form new positive habits but also provides tools and strategies to break away from negative ones, replacing them with healthier alternatives." },
  { question: "What kind of reminders does Habitify offer?", answer: "Habitify provides customizable reminders that can be tailored to your schedule, ensuring you stay on track with your habits every day." },
  { question: "Does Habitify offer habit tracking analytics?", answer: "Yes, Habitify provides detailed analytics on your habit streaks, success rates, and patterns, offering insights into your habit formation journey." },
  { question: "How can I share my progress with friends or family?", answer: "Habitify allows you to easily share your habit tracking progress and milestones with friends or family, fostering a sense of accountability and community." },
  { question: "Is there a premium version of Habitify, and what does it offer?", answer: "Habitify offers a premium version with advanced features such as unlimited habits, detailed analytics, personalized coaching, and priority support." },
  { question: "How do I start with Habitify?", answer: "Getting started is easy. Simply download the app, set your goals, and let Habitify guide you through the process of forming new habits with AI-powered insights and reminders." },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-content">
        {faqData.map((faq, index) => (
          <div key={index} className={`faq-item ${activeIndex === index ? 'expanded' : ''}`} onClick={() => toggleFAQ(index)}>
            <h4>{faq.question}<span className="toggle-icon">{activeIndex === index ? '-' : '+'}</span></h4>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
      <div className="faq-image">
        {/* <img src={FaqImage} alt="Frequently Asked Questions" /> */}
      </div>
    </div>
  );
};

export default FAQ;
